.footer {
  div {
    background-color: var(--dark-blue);
    padding: 1%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      color: white;
    }

    img {
      margin-left: 1vw;
      width: 10vw;
    }
  }

  section {
    background-color: var(--light-blue);
    padding: 5%;
    display: grid;
    grid-template-columns: 50% 50%;
    border-top-left-radius: 10vw;
    border-top-right-radius: 10vw;

    a {
      text-decoration: none;
      transition: transform 0.5s;

      article {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 15%;
        }

        p {
          margin-left: 1vw;
          font-size: 1.5vw;
          line-height: 1.2em;

          span {
            font-size: 1.5vw;
            font-weight: bold;
          }
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .footer {
    div {
      padding: 10px 1%;

      img {
        margin-left: 10px;
        width: 170px;
      }
    }

    section {
      a {
        article {
          img {
            width: 120px;
          }

          p {
            font-size: 18px;
            margin-left: 10px;

            span {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .footer {
    div {
      img {
        width: 200px;
      }
    }

    section {
      grid-template-columns: 100%;

      a {
        width: 400px;
        margin: 0 auto;

        article {
          justify-content: flex-start;

          img {
            width: 100px;
            margin: 10px 0;
          }

          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 580px) {
  .footer {
    div {
      p {
        font-size: 3vw;
      }

      a {
        img {
          width: 30vw;
        }
      }
    }

    section {
      a {
        width: 100%;

        article {
          img {
            width: 20%;
          }

          p {
            font-size: 3.5vw;
          }
        }
      }
    }
  }
}
