$light_blue: #c5cbE7;
$dark_blue: #181a46;
$orange: #fbb040;
$dark_orange: #f7941d;

:root {
  --light-blue: #c5cbE7;
  --dark-blue: #181a46;
  --orange: #fbb040;
  --dark-orange: #f7941d;
}
