@import "variables";

/* ScrollBar */
::-webkit-scrollbar {
  width: 0.8vw;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

::-webkit-scrollbar-thumb {
  background: darken($light_blue, 15%);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: darken($light_blue, 30%);
}

@font-face {
  font-family: "Biko";
  src: url("bikoRegular.otf") format("opentype");
}

@font-face {
  font-family: "Biko";
  src: url("bikoBold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Biko-black";
  src: url("bikoBolder.otf") format("opentype");
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  font-family: 'Biko', sans-serif;
  font-weight: normal;
  font-size: 1vw;
  text-decoration: none;
  color: $dark_blue;
}

h1,
h2,
h3,
h4 {
  font-weight: bold;
}

h1 {
  font-size: 3.5vw;
  margin: 1vw 0;
}

h2 {
  font-size: 2.4vw;
  margin: 1vw 0;
  color: darken($dark_orange, 5%);
}

h3 {
  font-size: 1.9vw;
  margin: 1vw 0;
}

p {
  font-size: 1.2vw;
}

button {
  color: $orange;
  background: lighten($dark_blue, 15%);
  font-family: 'Biko-black', sans-serif;
  padding: 0.7vw 1.2vw;
  font-size: 2vw;
  border-radius: 1.2vw;
  border: none;
  cursor: pointer;

  &:hover {
    background: lighten($dark_blue, 5%);
  }
}

ul {
  li {
    font-size: 1.2vw;
    margin: 0.8vw 0;
  }
}

.img-container {
  width: 50%;
  padding: 5%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 50%;
  }
}

@media (max-width: 1600px) {
  ::-webkit-scrollbar {
    width: 10px;
  }

  h1 {
    margin: 40px 0;
  }

  h2 {
    font-size: 35px;
    margin: 15px 0;
  }

  h3 {
    font-size: 25px;
    margin: 15px 0;
  }

  p {
    font-size: 20px;
  }

  ul {
    li {
      font-size: 20px;
      margin: 12px 0;
    }
  }

  button {
    padding: 10px 20px;
    font-size: 33px;
    border-radius: 20px;
  }

  .img-container {
    padding: 40px 5%;
  }
}

@media (max-width: 900px) {
  .img-container {
    width: 100%;

    img {
      width: 50%;
    }
  }
}
